
import { useScroll } from '@vueuse/core'
import { metaDataMixin } from '~/mixins'

export default {
  mixins: [metaDataMixin],
  layout: "candles",
  async asyncData({ $api, $config, $log, route }) {
    let candles = [], candlesCount = 0
    
    try {
      // Get all candles
      const { data: { items = [], meta: { total = 0 } } = {} } = await $api.candles.getAll({
        domain: $config.preprEnvironmentDomain,
        limit: 100,
      })
      candles = items
      candlesCount = total
    } catch (e) {
      $log.warn(`Couldn't load candles on the overview page.`, e)
    }
    
    // Get spotlighted candle
    if (route.query?.kaars && candles.filter(candle => candle.id !== route.query.kaars).length <= 0) {
      try {
        const { data: { item = {} } = {} } = await $api.candles.getOne(
          route.query.kaars,
          {
            domain: $config.preprEnvironmentDomain,
          })
          candles.unshift(item)
          candlesCount += 1
      } catch (e) {
        $log.warn(`Couldn't fetch highlighted candle: ${route.query.kaars}.`, e)
      }
    }

    return {
      candles,
      candlesCount,
    }
  },
  data () {
    return {
      candlesCount: 0,
      isScrolling: false,
      isFetching: false,
      scrollContainer: null,
      candles: {
        type: Array,
        default: () => [],
      },
      seo: {
        title: 'Kaarsjes voor mensen die we missen',
        description: 'Online kaarsjes voor de dierbaren die we missen. Steek ook een kaarsje aan voor wie jij mist.',
        image: 'https://ikmisje-eo.cdn.eo.nl/{format}/2lkbd7o2r2v9-28pxfph9zoyh-candles-og-image.png',
        keywords: 'online kaarsje branden, overledene gedenken, mindfull rouwen, kaarsje aansteken, overledene herinneren',
      },
      social: {
        title: 'Kaarsjes voor mensen die we missen',
        description: 'Online kaarsjes voor de dierbaren die we missen. Steek ook een kaarsje aan voor wie jij mist.',
        image: 'https://ikmisje-eo.cdn.eo.nl/{format}/2lkbd7o2r2v9-28pxfph9zoyh-candles-og-image.png',
      },
    }
  },
  watch: {
    // Reload list when we return to this page
    '$route.path': function (prev, next) {
      if (prev == '/kaarsen/aansteken' && next == '/kaarsen') {
        this.getCandles()
      }
    },
  },
  mounted () {
    this.isScrolling = useScroll(this.$refs.grid).isScrolling
  },
  methods: {
    async getCandles (offset = 0, limit = 100) {
      if (this.isFetching) return
      this.isFetching = true
      try {
        const { data: { items = [], meta: { total = 0 } } = {} } = await this.$api.candles.getAll({
          domain: this.$config.preprEnvironmentDomain,
          limit,
          offset,
        })
        if (offset > 0) {
          this.candles = [...this.candles, ...items]
        } else {
          this.candles = items
        }
        this.candlesCount = total
      } catch (e) {
        this.$log.warn(`Couldn't fetch candles on the overview page.`, e)
      }
      this.isFetching = false
    },
  },
}
